import generateRoutes from "./generateRoutes";
const BaseURL = "https://petpals.sys.redcode.se/API";
const { Default, API, Hubs } = generateRoutes(BaseURL);

export const environment = {
  production: true, 
  api: API, 
  hub: Hubs, 
  ...Default
}
