export default function (BaseURL: string) {
  const Default = {
    defaultProfilePicture: `assets/images/defaultProfilePicture.png`,
    defaultDogPicture: `assets/images/defaultDogPicture.jpg`,
  }

  const Hubs = {
    message: `${BaseURL}/MessageHub`,
    notification: `${BaseURL}/NotificationHub`
  }

  const Base = {
    Animal: `${BaseURL}/Animal`,
    Auth: `${BaseURL}/Auth`,
    Chat: `${BaseURL}/Chat`,
    Comment: `${BaseURL}/Comment`,
    Events: `${BaseURL}/EventInfo`,
    File: `${BaseURL}/File`,
    Friend: `${BaseURL}/Friendship`,
    Gallery: `${BaseURL}/Gallery`,
    Like: `${BaseURL}/Like`,
    Map: `${BaseURL}/Map`,
    Notification: `${BaseURL}/Notification`,
    Post: `${BaseURL}/Post`,
    Room: `${BaseURL}/Room`,
    User: `${BaseURL}/User`,
  }

  const API = {
    animal: {
      getAll: Base.Animal,
      register: Base.Animal,
      update: Base.Animal,
      delete: Base.Animal,
      profileImage: {
        update: Base.Animal + '/Gallery/ProfilePicture'
      },
      getFamilyTree: Base.Animal + '/Tree'
    },
    authenticate: {
      getAuthentication: Base.Auth,
      logOut: Base.Auth + '/logOut',
      validateEmail: Base.Auth + '/Validate',
      resetPasswordRequest: Base.Auth + '/ResetPasswordRequest',
      resetPassword: Base.Auth + '/ResetPassword',
      refreshToken: Base.Auth + '/Refresh',
    },
    basePath: BaseURL,
    comment: {
      forPost: Base.Comment + '/Post',
      getSubcomments: Base.Comment + '/SubComment',
      postNew: Base.Comment
    },
    events: {
      create: Base.Events,
      getAllEvents: Base.Events,
      getEventById: Base.Events + '/Single',
      update: Base.Events,
      delete: Base.Events,
    },
    file: {
      upload: Base.File + '/Upload',
      save: Base.File + '/Save',
      getOne: Base.File
    },
    friendship: {
      requests: Base.Friend + '/Requests',
      getSingle: Base.Friend,
      create: Base.Friend,
      accept: Base.Friend + '/Accept',
      update: Base.Friend,
      delete: Base.Friend + '/Remove',
    },
    gallery: {
      image: {
        user: {
          get: Base.Gallery + '/User/Image',
          add: Base.Gallery + '/User/Image/Add',
          delete: Base.Gallery + '/User/Image/Delete'
        },
        animal: {
          get: Base.Gallery + '/Animal/Image',
          add: Base.Gallery + '/Animal/Image' + '/Add',
          delete: Base.Gallery + '/Animal/Image' + '/Delete'
        },
      }
    },
    like: {
      toggle: Base.Like + '/Toggle'
    },
    map: {
      markers: {
        getAll: Base.Map + '/getMapMarkers',
        getTotalLikes: Base.Map + '/getMapMarkerLike',
      }
    },
    messages: {
      base: Base.Room,
      replyToMessage: Base.Room + '/Reply',
      getConnectedUsers: Base.Chat + '/GetConnectedUsers',
      userSuggestions: Base.User + '/Suggestions',
      room: {
        create: Base.Room + '/GetRoom',
        getMessages: Base.Room + '/Messages',
        getCurrentRooms: Base.User + '/Rooms',
        user: Base.Room + '/User',
        findShared: Base.Room + '/Shared'
      }
    },
    notification: {
      update: Base.Notification + '/Update',
      markAsRead: Base.Notification + '/Read'

    },
    post: {
      getOne: Base.Post + '/Single',
      getAll: Base.Post,
      create: Base.Post,
      update: Base.Post,
      delete: Base.Post,
    },
    user: {
      getUser: Base.User,
      getMedia: Base.User + '/Media',
      authenticate: Base.Auth,
      friends: Base.User + '/Friends',
      animals: Base.User + '/Animals',
      register: Base.User + '/Register',
      update: Base.User + '/Update',
      getData: Base.User + '/Sok/Pnr',
      getNotifications: Base.User + '/Notifications'
    }
  }
  return {Default, API, Hubs}
}
